.cg-gallery-item {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.cg-gallery-image-part {
	position: relative;
	overflow: hidden;
}

.cg-gallery-item--playable {

	.cg-gallery-image-part::after {
		content: '';
		width: 50px;
		height: 50px;
		background: url('play.svg') no-repeat center center;
		background-size: contain;
		display: inline-block;
		position: absolute;
		left: calc(50% - 25px);
		top: calc(50% - 25px);
		transform-origin: center center;
		transition: all 0.3s;
		opacity: 0.667;
	}

	&:hover {

		.cg-gallery-image-part::after {
			transform: scale(1.4);
			opacity: 1;
		}

	}

}

@media (hover: hover) {
	.cg-gallery-hover-fx .cg-gallery-item {

		.cg-gallery-image {
			transition: transform 0.3s;
		}

		&:hover .cg-gallery-image {
			transform: scale(1.075);
		}

	}
}

.cg-gallery-enlarged-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	box-sizing: border-box;
	pointer-events: none;
}

.cg-gallery-enlarged-container-html {
	pointer-events: auto;
	max-width: 100vw;
	max-height: calc(100vh - 50px);
}

.cg-gallery-enlarged-video {
	max-width: 100vw;
	max-height: 85vh;
	pointer-events: auto;
}

.cg-gallery-enlarged-iframe {
	max-width: 100vw;
	max-height: 85vh;
	width: 1024px;
	height: 576px;
	pointer-events: auto;
	border: none;
}

.cg-gallery-label {
	line-height: 1.3;
	font-size: 14px;
	padding: 6px 0;
}




@import 'rows-gallery';
@import 'columns-gallery';
@import 'strip-gallery';
@import 'single-gallery';
@import 'swiper-gallery';
@import 'mosaic-gallery';

@import 'layouts';
@import 'effects';
